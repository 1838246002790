export const MODAL_ID = {
  COMMON: {
    CONFIRM: 'modal-common-confirm',
    LOGOUT: 'log-out',
    LOG_OUT_STUDENT: 'log-out-student',
    REMEMBER: 'rememver-password',
  },
  ADMIN: {
    TRANNING_MANAGEMENT: {
      CLASS_MANAGEMENT: {
        CREATE: 'create-class',
        ADD_USER: 'add-user',
        SHOW_USER: 'show-user',
        ADD_LESSON: 'add-lesson',
        SETTING_LESSON: 'setting-lesson',
        CERTIFICATE: 'certificate',
        IMAGE_CERTIFICATE: 'image-certificate',
        DELETE: 'delete-class',
        LIST_USER_BY_GROUP: 'list-user-by-group',
        HISTORY_EXAM: 'history_exam',
      },
    },
    COURSE: {
      CREATE_COURSE: 'create-course',
      UPDATE_COURSE: 'update-course',
      DELETE_COURSE: 'delete-course',
      ASSIGN_STAFF: 'assign-staff',
      DELETE_STAFF_COURSE: 'delete-staff-course',
      STAFF_COURSE_DETAIL: 'staff-course-detail',
      CREATE_QUESTION_LESSON: 'create-question-lesson',
      ASSIGN_QUESTION_LESSON: 'assign-question-lesson',
      DELETE_QUESTION_LESSON: 'delete-question-lesson',
      VIEW_FILE_DOCUMENT: 'view-file-document',
      LIST_COURSE_OF_STAFF: 'course-of-staff',
      EDIT_CERTIFICATE: 'edit-certificate',
      TEMPLATE: 'template',
    },
    CURRICULUM: {
      CREATE_CURRICULUM: 'create-curriculum',
      UPDATE_CURRICULUM: 'update-curriculum',
      DELETE_CURRICULUM: 'delete-curriculum',
    },
    QUESTION: {
      CREATE_QUESTION: 'create-question',
      UPDATE_QUESTION: 'update-question',
      DELETE_QUESTION: 'delete-question',
    },
    POSITION: {
      CREATE_POSITION: 'create-position',
      UPDATE_POSITION: 'update-position',
      DELETE_POSITION: 'delete-position',
    },
    DEPARTMENT: {
      CREATE_DEPARTMENT: 'create-department',
      UPDATE_DEPARTMENT: 'update-department',
      DELETE_DEPARTMENT: 'delete-department',
    },
    COMPANY: {
      CREATE_COMPANY: 'create-company',
      DELETE_COMPANY: 'delete-company',
      EDIT_COMPANY: 'edit-company',
    },
    REPORT: {
      CREATE_REPORT: 'create-report',
      UPDATE_REPORT: 'update-report',
      DELETE_REPORT: 'delete-report',
    },
    LESSON: {
      CREATE_LESSON: 'create-lesson',
      UPDATE_LESSON: 'update-lesson',
      DELETE_LESSON: 'delete-lesson',
      VIEW_FILE: 'view-file',
      VIEW_VIDEO: 'view-video',
    },
    DOCUMENT: {
      CREATE_DOCUMENT: 'create-document',
      UPDATE_DOCUMENT: 'update-document',
      DELETE_DOCUMENT: 'delete-document',
      VIEW_DOCUMENT: 'view-document',
    },
    CONTENT: {
      CREATE_CONTENT: 'create-content',
      UPDATE_CONTENT: 'update-content',
      DELETE_CONTENT: 'delete-content',
    },
    USER: {
      CREATE_USER: 'create-user',
      IMPORT_USER: 'import-user',
      UPDATE_USER: 'update-user',
      DELETE_USER: 'delete-user',
      CHANGE_PASSWORD_USER: 'change-password-user',
    },
    FILE: {
      VIEW: 'view-file',
    },
    WEB_OPERATIONS: {
      NEWS_CATEGORY: {
        CREATE: 'create-category',
        EDIT: 'edit-category',
        DELETE: 'delete-category',
      },
      NOTIFICATIONS: {
        DELETE: 'delete-notifications',
        CREATE: 'create-notifications',
      },
      TEMPLATES: {
        CREATE: 'create-template',
        EDIT: 'edit-template',
      },
      NEWS: {
        CREATE: 'create-news',
        EDIT: 'edit-news',
        DELETE: 'delete-news',
      },
    },
    LEARNING_MATERIALS: {
      TEACHER: {
        CREATE: 'create-teacher',
        EDIT: 'edit-teacher',
        DELETE: 'delete-teacher',
      },

      DOCUMENTS: {
        CREATE: 'create-documents',
        EDIT: 'edit-documents',
        DELETE: 'delete-documents',
      },

      COURSE_CATALOGS: {
        DELETE: 'delete-course-catalog',
        EDIT: 'edit-course-catalog',
      },
      QUESTION_CATALOGS: {
        CREATE: 'create-question-catalog',
        DELETE: 'delete-question-catalog',
        EDIT: 'edit-question-catalog',
      },
      LIST_TOPIC: {
        DELETE: 'delete-topic',
        EDIT: 'edit-topic',
      },
      LEVEL_QUESTION: {
        CREATE: 'create-level-question',
        EDIT: 'edit-level-question',
        DELETE: 'delete-level-question',
      },
      EXAM: {
        DETAIL: 'detail-question',
        HISTORY_ACTIONS: 'history-actions',
      },
      COURSES: {
        CHAPTERS: {
          CREATE: 'create-chapter',
          UPDATE: 'update-chapter',
        },
      },
      RANKING: {
        DELETE: 'delete-ranking',
        IMPORT: 'import-ranking',
      },
    },

    SETTING_WEB: {
      SIGN_UP: {
        CREATE: 'create-register',
        DELETE: 'delete-register',
        EDIT: 'edit-register',
      },
    },
    USER_MANAGEMENT: {
      SITE: {
        CREATE_SITE: 'create-site',
        EDIT_SITE: 'edit-site',
        DELETE_SITE: 'delete-site',
      },
      USER_GROUP: {
        DELETE: 'delete-user-group',
      },
      POSITIONS: {
        DELETE: 'delete-position',
        CREATE: 'create-position',
        UPDATE: 'update-position',
      },
      USER_AUTHOR: {
        AUTH_GROUP: {
          DELETE: 'delete-user-author',
          EDIT_DATA_FEATURE: 'auth-group edit-data-feature',
        },
        AUTH_USER: {
          EDIT_DATA_FEATURE: 'auth-user edit-data-feature',
        },
      },
    },
    MCERTIFICATEMANAGEMENT: {
      MCERTIFICATE: {
        DELETE: 'delete-mcertificate'
      }
    }
  },
  TRAINEE: {
    CERTIFICATE: {
      DETAIL: 'certificate-detail',
    },
  },
  STAFF: {
    ON_COURSE: 'on-course',
    FILE: {
      VIEW_FILE: 'view-file',
      DELETE_FILE: 'delete-file',
      SUBMIT_FILE: 'submit-flie',
    },
    DOCUMENT: {
      VIEW_DOCUMENT: 'view-document-1',
    },
    CERTIFICATE: {
      DOWNLOAD: 'download-certificate',
    },
    EXAM: {
      SUBMIT_EXAM: 'submit-exam',
      HISTORY_ACTIONS: 'history-actions',
      MODAL_RESULT_EXAM: 'modal-result-exam',
      MODAL_RESULT_EXAM_VIEW: 'modal-result-exam-view',
      MODAL_CERTIFICATE_EXAM: 'modal-certificate-exam',
    },
    COURSE: {
      SUBMIT_EXERSICE: 'submit-exersice',
    },
  },

  SUPER_ADMIN: {
    COMPANY: {
      CREATE_COMPANY: 'create-company-super',
      DELETE_COMPANY: 'delete-company-super',
      EDIT_COMPANY: 'edit-company-super',
    },
    ADMIN: {
      CREATE_ADMIN: 'create-admin-super',
      UPDATE_ADMIN: 'update-admin-super',
      DELETE_ADMIN: 'delete-admin-super',
      CHANGE_PASS: 'change-pass-admin-super',
    },
    COURSE: {
      CREATE_COURSE: 'create-course-super',
      UPDATE_COURSE: 'update-course-super',
      DELETE_COURSE: 'delete-course-super',
      ASSIGN_STAFF: 'assign-staff-super',
    },
    LESSON: {
      CREATE_LESSON: 'create-lesson-super',
      UPDATE_LESSON: 'update-lesson-super',
      DELETE_LESSON: 'delete-lesson-super',
      VIEW_FILE: 'view-file-super',
      VIEW_VIDEO: 'view-video-super',
    },
    DOCUMENT: {
      CREATE_DOCUMENT: 'create-document-super',
      UPDATE_DOCUMENT: 'update-document-super',
      DELETE_DOCUMENT: 'delete-document-super',
      VIEW_DOCUMENT: 'view-document-super',
    },
    CERTIFICATE: {
      CREATE: 'create-certificate-super',
    },
    QUESTION_ADMIN: {
      CREATE: 'create-question-super',
      DELETE: 'delete-question-super',
    },
    DIAGRAM: {
      DELETE: 'delete-diagram',
      VIEW_MEMBER: 'member-diagram',
    },
  },
  STUDENT: {
    NOTE: {
      DELETE: 'delete-note',
      CREATE: 'create-note',
      EDIT: 'edit-note',
    },
    CLASS: {
      LIST_DOC: 'list-documents',
      DELETE_COMMENT: 'delete-comment',
      CONDITIONAL: 'list-conditional-course',
      LIST_CLASS: 'list-class',
    },
  },
};
